import styled from 'styled-components';

export const QuestionOuter = styled.div`
 margin: 0;
`

export const NotificationWrap = styled.div`
 ${tw`pb-4 pt-4 `};
 min-height: 50px;
`

export const QuestionWrap = styled.div`
 margin: 0;
 ${tw`pb-4 display-flex`};
 flex-direction: column;
 display: inline-flex;
 ul {
     list-style: none;
     margin: 0;
     padding: 0;
     position: relative;
 }
`
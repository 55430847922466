import React from 'react'
import PropTypes from 'prop-types'
import { AnswerWrap } from '../styles/common'

export default function Answer({answer, handleClick, isDisabled}) {
    return(
        <>
        <AnswerWrap className={`${isDisabled && !answer.isCorrect ? "hide-me" : ""}`}>
            <label className={`option ${isDisabled ? "disabled" : ""}`}>
                <input className="option__input" type="radio" name="question" required id={answer._key} onClick={ () => handleClick(answer) } disabled={isDisabled}/>
                <span className="option__text">{answer.text}</span>
            </label>
        </AnswerWrap>
    </>
    )
};

  Answer.propTypes = {
    answer: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
}
import React, { useState, useStaticQuery } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout'
import H1 from '../components/h1'
import Button from '../components/button'
import MainWrap from '../components/mainwrap'
import Answer from '../components/answer'
import Img from 'gatsby-image'
import Notification from '../components/notification'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { NotificationWrap, QuestionOuter, QuestionWrap } from '../styles/pages/quiz'
import useCampaignQuery from '../graphql/queries'

export default function Quiz( ) {

    const { sanityCampaign } = useCampaignQuery()
    
    const updateStep = () => {

        setWrongAnswer(false)
        setAnsweredWrong(null);
        setCorrectAnswer(null)
        setAnswered(false)
        setAnswer(null)

        if (step !== (quizData.length)) {
            setStep( (prevStep) => prevStep + 1)
        } else {
            setFinishedQuiz(true)
        }
    }

    const checkAnswer = (answer) => {
        setWrongAnswer(false)
        setAnsweredWrong(null);
        setCorrectAnswer(null)
        setAnswered(false)
        
        if (step === (quizData.length - 1) && answer.isCorrect === true) {
            setFinishedQuiz(true);
        }
           
        if (answer.isCorrect === true) {
            setCorrectAnswer(answer.reason)
            setAnswered(true)
        } else {
            setWrongAnswer(answer.reason)
            setAnsweredWrong(true);
            setAnswered(false);
        }   
    }

    const updateAnswer = (answer) => {
        setWrongAnswer(false)
        setAnsweredWrong(null);
        setCorrectAnswer(null)
        setAnswer(answer)
    }

    const quizData = sanityCampaign.questions
    
    
    const [step, setStep] = useState(0)
    const [answered, setAnswered] = useState(false)
    const [answeredWrong, setAnsweredWrong] = useState(false)
    const [finishedQuiz, setFinishedQuiz] = useState(false)
    const [wrongAnswer, setWrongAnswer] = useState()
    const [correctAnswer, setCorrectAnswer] = useState()
    const [answer, setAnswer] = useState(null);

    const isEnabled = answer;

    const RenderButton = () => {
        

        if (answered && !finishedQuiz && step <= (quizData.length - 1))  {
          return <Button title="Neste" handleClick={ updateStep } answer={answer} outline={true}/>
        } else if (!finishedQuiz) {
          return <Button title="Svar" handleClick={ checkAnswer } answer={answer} disabled={!isEnabled} outline={true}/>
        }
         return <Button title="Gå til registrering" handleClick={ () => { navigate("/registration") }} answers={null}  outline={true}></Button>
      }

return (
  <Layout>
    <MainWrap>

            {step !== (quizData.length) &&
                    <>
                    <H1 
                    subtitle={quizData[step].introText}
                    title={quizData[step].text} />

                    {quizData[step].image && <Img fluid={quizData[step].image.asset.fluid} alt={quizData[step].imageCaption} /> }

                    <NotificationWrap>
                        { (wrongAnswer && answeredWrong) && 
                            <Notification message={answer.reason} error={true}></Notification>
                        }
                        { (correctAnswer && answered) && 
                             <Notification message={answer.reason}></Notification>
                        }
                    </NotificationWrap>
                   
            <QuestionOuter>
            <QuestionWrap>
          
            <TransitionGroup className="answer-list" component="ul">
            { sanityCampaign.questions[step].answers.map((answer) => (
                <CSSTransition
                key={answer._key}
                timeout={0}
                transitionAppearTimeout={600}
                classNames="fade"
              >
                <Answer key={answer._key} answer={answer} handleClick={ updateAnswer } isDisabled={answered} />
                </CSSTransition>
                ))}
            </TransitionGroup>
            </QuestionWrap>
            </QuestionOuter>  
            </>
            }

            <RenderButton />

    </MainWrap>
  </Layout>
)}
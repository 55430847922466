import React from 'react'
import PropTypes from 'prop-types'
import { NotificationMsgWrap } from '../styles/common'

export default function Notification({message, error}) {
    return(
        <>
            <NotificationMsgWrap dangerouslySetInnerHTML={{ __html: message }} error={error}>
            </NotificationMsgWrap>
        </>
    )
};

Notification.propTypes = {
    message: PropTypes.string.isRequired,
    error: PropTypes.bool,
}